@import "../../variables.module"

.container
  display: flex
  flex-direction: column
  padding: .5rem 0

.bigButton
  margin: 2px 0px 0px 0px
  background-color: $blue-color
  font-weight: 600 !important
  border-radius: 6px
  color: #fff
  text-transform: none !important
  box-shadow: $light-shadow

.smallButton
  margin: 2px 0px 0px 10px
  min-width: 26px
  background-color: $blue-color
  font-weight: 600 !important
  border-radius: 6px
  color: #fff
  text-transform: none !important
  box-shadow: none

.filterLabel
  color: #8f9bb2
  margin-right: 6px
  font-size: 11px
  margin-bottom: 4px

.icon
  fill: #627ef7

.filterContainer
  padding-right: 14px
  display: flex
  flex-direction: column

.filterText
  input
    padding: 4px 12px
    background: $main-background-color
    border-radius: 4px
    font-size: 14px
    border: 1px solid #BCC6DD
  fieldset
    border: none

$divider-breakpoint-1: 1055px
$divider-breakpoint-2: 1453px

@media (max-width: $divider-breakpoint-1)
  .divider1
    display: none

@media (max-width: $divider-breakpoint-2)
  .divider2
    display: none
