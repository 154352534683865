$main-background-color: white;
$header-background-color: #F7F9FC;
$data-background-color: #E9EBF8;
$font-color: #0C0B1A;
$light-font-color: #6f6f72;
$secondary-font-color: #8C8C8C;
$black-color: #000000;
$gray-color: #383838;
$blue-color: #416CDE;
$light-blue-color: #BCCEFD;
$light-gray-color: #8F9BB2;
$light-gray-color-alt: #747e91;
$lighter-gray-color: #c3cadb;
$lightest-gray-color: #f6f8fb;
$success-color: #27AE60;
$light-success-color: #27ae5f2a;
$warning-color: #EDC800;
$light-warning-color: rgba(237, 201, 0, 0.22);
$darker-warning-color: #dab900;
$orange-color: #ed5700;
$failure-color: #EB5757;
$upgrade-color: #DB2156;
$light-upgrade-color: rgb(219 33 86 / 10%);

$light-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
$light-white-shadow: 0px 6px 6px 1px rgb(246 248 251 / 55%);

$header-section-color : #fbfcfe;
$content-section-color: #f8f9fc;

$text-font-family: Source Sans Pro, Roboto, Lucida Grande, Tahoma, sans-serif;

:export {
  mainBackgroundColor: $main-background-color;
  headerBackgroundColor: $header-background-color;
  dataBackgroundColor: $data-background-color;
  fontColor: $font-color;
  lightFontColor: $light-font-color;
  secondaryFontColor: $secondary-font-color;
  blackColor: $black-color;
  grayColor: $gray-color;
  lightGrayColor: $light-gray-color;
  lightGrayColorAlt: $light-gray-color-alt;
  lighterGrayColor: $lighter-gray-color;
  lightestGrayColor: $lightest-gray-color;
  blueColor: $blue-color;
  lightBlueColor: $light-blue-color;
  successColor: $success-color;
  lightSuccessColor: $light-success-color;
  warningColor: $warning-color;
  lightWarningColor: $light-warning-color;
  darkerWarningColor: $darker-warning-color;
  orangeColor: $orange-color;
  failureColor: $failure-color;
  lightUpgradeColor: $light-upgrade-color;
  upgradeColor: $upgrade-color;
  lightShadow: $light-shadow;
  lightWhiteShadow: $light-white-shadow;
  contentSectionColor: $content-section-color;
  textFontFamily: $text-font-family
}
