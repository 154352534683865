@import '../../../variables.module'

.row
  display: flex
  background: $main-background-color
  min-height: 30px
  max-height: 50px
  align-items: center
  padding: 0 8px
  border-radius: 4px
  border: solid 1px transparent
  margin-top: 10px


.marginLeft10
  margin-left: 10px !important

.title
  margin: auto
  margin-right: 10px
  font-weight: bold

.separator
  position: relative
  display: flex
  border-right: 1px solid $data-background-color
  padding-right: 12px

.button
  font-weight: 600 !important
  border-radius: 6px !important
  color: #fff
  text-transform: none !important
  font-size: 12px
  height: 24px
  white-space: nowrap
  box-shadow: $light-shadow

@media (max-width: 1400px)
  .pcapButtonWrapper
    display: none

@media (max-width: 1220px)
  .replayButtonWrapper
    display: none

@media (max-width: 1030px)
  .nodeWrapper
    display: none
