@import '../../../variables.module'

.ServiceMapViewContainer
    height: 100%
    padding: 0 24px
    padding-top: 10px
    padding-right: 16px
    padding-left: 16px
    display: flex
    flex-direction: column
    gap: 10px
    background-color: $data-background-color
