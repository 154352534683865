@import '../../variables.module'

.TrafficEntry
  position: relative
  width: 100%
  box-sizing: border-box
  display: flex
  gap: 3px
  background: $main-background-color
  align-items: center
  border-radius: 4px
  cursor: pointer
  border: solid 1px transparent
  margin-right: 10px
  &:not(:first-child)
    margin-top: 10px

  &:hover
    border: solid 1px lighten(#4253a5, 20%)

  &.selected
    border: 1px $blue-color solid

.DataText
  text-overflow: ellipsis
  white-space: nowrap
  color: $light-font-color
  font-family: 'Roboto', sans-serif
  font-size: 12px
  font-weight: 400
  letter-spacing: -0.48px

.padlock
  position: absolute
  top: -25px
  left: 5px
