@import '../../../variables.module'

.base
    display: inline-block
    text-align: center
    font-family: 'Roboto', sans-serif
    font-size: 12px
    font-weight: 700
    background-color: #000
    color: #fff

.vertical
    line-height: 22px
    letter-spacing: 0.5px
    max-width: 22px
    height: 52px
    border-radius: 0px 4px 4px 0
    writing-mode: vertical-lr
    transform: rotate(-180deg)
    text-orientation: mixed

.horizontal
    border-radius: 6px
    padding: 5px 10px
    box-shadow: $light-shadow
