@import "../../../variables.module"

.DocsLink
  display: flex
  align-items: center
  gap: 4px
  font-family: Roboto, sans-serif
  font-size: 12px
  font-style: normal
  font-weight: 700
  text-decoration: none
